import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Layout

const PublicLayout = React.lazy(() =>
  import("./Layout/PublicLayout/PublicLayout.js")
);

const AccountLayout = React.lazy(() =>
  import("./Layout/AccountLayout/AccountLayout.js")
);

// Errors

const ForbiddenPage = React.lazy(() =>
  import("./Pages/ForbiddenPage/ForbiddenPage.js")
);

const Error404Page = React.lazy(() =>
  import("./Pages/Error404Page/Error404Page.js")
);

// Superadmin routes

const superadminRoutes = {
  path: "superadmin",
  children: [
    {
      index: true,
      Component: React.lazy(() =>
        import("./Pages/Superadmin/AccountPage/AccountPage.js")
      ),
    },
    {
      path: "respondentsRegistry",
      Component: React.lazy(() =>
        import("./Pages/Superadmin/RespondentsListPage/RespondentsListPage.js")
      ),
    },
    {
      path: "respondentProfile/:id",
      Component: React.lazy(() =>
        import(
          "./Pages/Superadmin/RespondentProfilePage/RespondentProfilePage.js"
        )
      ),
    },
    {
      path: "newRespondent",
      Component: React.lazy(() =>
        import("./Pages/Superadmin/NewRespondentPage/NewRespondentPage.js")
      ),
    },
    {
      path: "importQueue",
      Component: React.lazy(() =>
        import("./Pages/Superadmin/ImportQueueListPage/ImportQueueListPage.js")
      ),
    },
  ],
};

// Admin routes

const adminRoutes = {
  path: "admin",
  children: [
    {
      index: true,
      Component: React.lazy(() =>
        import("./Pages/Admin/AccountPage/AccountPage.js")
      ),
    },
    {
      path: "dashboard",
      Component: React.lazy(() =>
        import("./Pages/Admin/DashboardPage/DashboardPage.js")
      ),
    },
    {
      path: "reports",
      children: [
        {
          path: "registry",
          Component: React.lazy(() =>
            import("./Pages/Admin/ReportsListPage/ReportsListPage2.js")
          ),
        },
        {
          path: "info/:id",
          Component: React.lazy(() =>
            import("./Pages/Admin/ReportInfoPage/ReportInfoPage.js")
          ),
        },
        {
          path: "tree/:sender_id",
          Component: React.lazy(() =>
            import(
              "./Pages/Admin/ReportAggregationTree/ReportAggregationTree.js"
            )
          ),
        },
        {
          path: "templateEditor/:id",
          Component: React.lazy(() =>
            import(
              "./Pages/Admin/ReportTemplateEditorPage/ReportTemplateEditorPage.js"
            )
          ),
        },
        {
          path: "templateJSONEditor/:id",
          Component: React.lazy(() =>
            import(
              "./Pages/Admin/ReportTemplateJSONPage/ReportTemplateJSONPage2.js"
            )
          ),
        },
        {
          path: "templateImport/:id",
          Component: React.lazy(() =>
            import(
              "./Pages/Admin/reportTemplateImportPage/reportTemplateImportPage.js"
            )
          ),
        },
      ],
    },
    {
      path: "classifiers",
      children: [
        {
          path: "registry",
          Component: React.lazy(() =>
            import("./Pages/Admin/ClassifiersListPage/ClassifiersListPage.js")
          ),
        },
        {
          path: "editor/:id",
          Component: React.lazy(() =>
            import("./Pages/Admin/ClassifierEditorPage/ClassifierEditorPage.js")
          ),
        },
      ],
    },
  ],
};

// Legal entity owner routes

const legalEntityOwnerRoutes = {
  path: "legalEntityOwner",
  children: [
    {
      index: true,
      Component: React.lazy(() =>
        import("./Pages/LegalEntityOwner/AccountPage/AccountPage.js")
      ),
    },
    {
      path: "users",
      Component: React.lazy(() =>
        import("./Pages/LegalEntityOwner/UsersPage/UsersPage.js")
      ),
    },
    {
      path: "sign",
      Component: React.lazy(() =>
        import("./Pages/LegalEntityOwner/ReportSignPage/ReportSignPage.js")
      ),
    },
  ],
};

// Respondent routes

const respondentRoutes = {
  path: "participant",
  children: [
    {
      index: true,
      Component: React.lazy(() =>
        import("./Pages/Participant/AccountPage/AccountPage.js")
      ),
    },
    {
      path: "profile",
      Component: React.lazy(() =>
        import("./Pages/Participant/ProfilePage/ProfilePage.js")
      ),
    },
    {
      path: "reportReview/:id",
      Component: React.lazy(() =>
        import("./Pages/Participant/ReviewPage/ReviewPage.js")
      ),
    },
    {
      path: "reportProcess/:id",
      Component: React.lazy(() =>
        import("./Pages/Participant/SubmissionPage/SubmissionPage.js")
      ),
    },
    {
      path: "npo",
      Component: React.lazy(() => import("./Pages/NPO/MainPage/MainPage.js")),
    },
    {
      path: "npo-projects",
      Component: React.lazy(() =>
        import("./Pages/NPO/ProjectsPage/ProjectsPage.js")
      ),
    },

    {
      path: "income",
      children: [
        {
          path: "list",
          Component: React.lazy(() =>
            import(
              "./Pages/Participant/IncomeReports/IncomeReportsPage/IncomeReportsPage.js"
            )
          ),
        },
        {
          path: "stats/:id",
          Component: React.lazy(() =>
            import(
              "./Pages/Participant/IncomeReports/SubmissionStatsPage/SubmissionStatsPage.js"
            )
          ),
        },
        {
          path: "history",
          Component: React.lazy(() =>
            import(
              "./Pages/Participant/IncomeReports/HistoryPage/HistoryPage.js"
            )
          ),
        },
      ],
    },
    {
      path: "outcome",
      children: [
        {
          path: "required",
          Component: React.lazy(() =>
            import(
              "./Pages/Participant/OutcomeReports/OutcomeReportsPage/OutcomeReportsPage.js"
            )
          ),
        },
        {
          path: "drafts",
          Component: React.lazy(() =>
            import(
              "./Pages/Participant/OutcomeReports/DraftsPage/DraftsPage.js"
            )
          ),
        },
        {
          path: "history",
          Component: React.lazy(() =>
            import(
              "./Pages/Participant/OutcomeReports/HistoryPage/HistoryPage.js"
            )
          ),
        },
      ],
    },
  ],
};

// Support routes

const supportRoutes = {
  path: "support",
  children: [
    {
      path: "contacts",
      Component: React.lazy(() =>
        import("./Pages/Support/ContactsPage/ContactsPage.js")
      ),
    },
    {
      path: "guides",
      Component: React.lazy(() =>
        import("./Pages/Support/GuidesPage/GuidesPage.js")
      ),
    },
    {
      path: "qa",
      Component: React.lazy(() => import("./Pages/Support/QAPage/QAPage.js")),
    },
  ],
};

//

const publicRoutes = {
  path: "/",
  element: <PublicLayout />,
  children: [
    {
      index: true,
      Component: React.lazy(() =>
        import("./Pages/StatisticsPage/StatisticsPage.js")
      ),
    },
    {
      path: "npo",
      Component: React.lazy(() => import("./Pages/NPO/MainPage/MainPage.js")),
    },
    {
      path: "npo-projects",
      Component: React.lazy(() =>
        import("./Pages/NPO/ProjectsPage/ProjectsPage.js")
      ),
    },
    {
      path: "about",
      Component: React.lazy(() => import("./Pages/AboutPage/AboutPage.js")),
    },
    {
      path: "login",
      Component: React.lazy(() => import("./Pages/LoginPage/LoginPage.js")),
    },
  ],
};

//

const protectedRoutes = {
  path: "/",
  element: <AccountLayout />,
  children: [
    superadminRoutes,
    adminRoutes,
    legalEntityOwnerRoutes,
    respondentRoutes,
    supportRoutes,
  ],
};

//

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <Error404Page />,
    children: [publicRoutes, protectedRoutes],
  },
]);

//

function AppRouter() {
  return <RouterProvider router={router} />;
}

export default AppRouter;
